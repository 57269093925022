import React from 'react'
import { groupCitiesByAlphabet, groupCitiesByOffset } from '../utils/constants'
import CityList from './CityList'
import styles from '../styles/homepage.module.css'

const BottomContent = ({ currentTime, timezones }) => {
  const groupedCities = groupCitiesByAlphabet(timezones)
  const groupedTimezones = groupCitiesByOffset(timezones)
  return (
    <>
      <p className="section_heading">Major Cities</p>
      <CityList citiesData={groupedCities} currentTime={currentTime} />
      <div className={styles.timezone_wrapper}>
        <p className="section_heading">Time Zones</p>
        <CityList
          type="timezone"
          citiesData={groupedTimezones}
          currentTime={currentTime}
        />
      </div>
    </>
  )
}

export default BottomContent
