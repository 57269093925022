import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './Homepage'
import CountryCityPage from './CountryCityPage'
import '../styles/index.css'
import CityPage from './CityPage'
import { SnackbarProvider } from 'notistack'

const App = () => {
  return (
    <SnackbarProvider
      maxSnack={2}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/city/:cityName" element={<CityPage />} />
          <Route path="*" element={<CountryCityPage />} />
        </Routes>
      </Router>
    </SnackbarProvider>
  )
}

export default App
