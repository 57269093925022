import React from 'react'
import styles from '../styles/cities.module.css'
import CityCard from './CityCard'

const CityList = ({ type = 'city', citiesData, currentTime }) => {
  if (type === 'city') {
    return (
      <div className={styles.major_cities_container}>
        {Object.keys(citiesData)
          .sort()
          .map((group) => (
            <div key={group} className={styles.city_group}>
              <p className={styles.hr_lines}>{group}</p>
              <div className={styles.city_card_wrapper}>
                {citiesData[group].map((city, index) => (
                  <CityCard
                    type={type}
                    key={index}
                    city={city}
                    currentTime={currentTime}
                  />
                ))}
              </div>
            </div>
          ))}
      </div>
    )
  } else {
    return (
      <div className={styles.major_cities_container}>
        {Object.keys(citiesData)
          .sort((a, b) => parseFloat(a) - parseFloat(b))
          .map((group) => {
            let formattedGroup
            const groupValue = parseFloat(group).toFixed(2)
            formattedGroup = groupValue >= 0 ? `+${groupValue}` : groupValue
            return (
              <div key={group} className={styles.city_group}>
                <p className={styles.hr_lines}>{formattedGroup}</p>
                <div className={styles.city_card_wrapper}>
                  {citiesData[group].map((city, index) => (
                    <CityCard
                      type={type}
                      key={index}
                      city={city}
                      currentTime={currentTime}
                    />
                  ))}
                </div>
              </div>
            )
          })}
      </div>
    )
  }
}

export default CityList
