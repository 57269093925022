import React, { useState } from 'react'
import styles from '../styles/header.module.css'
import LogoIcon from '../../assets/images/logo.svg'
import DownIcon from '../../assets/images/downIcon.svg'
import MenuIcon from '../../assets/images/burger-menu.svg'
import useToast from '../hooks/useToast'

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const { showToast } = useToast()

  const handleDropdownToggle = (event) => {
    event.preventDefault()
    setIsDropdownOpen(!isDropdownOpen)
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const handleMobileDropdownToggle = (e) => {
    e.preventDefault()
    setIsActive(!isActive)
  }
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    const pageUrl = window.location.href
    navigator.clipboard
      .writeText(pageUrl)
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
        showToast('Link Copied!', 'success')
      })
      .catch((err) => console.error('Failed to copy the link: ', err))
  }

  return (
    <>
      {/* header mobile */}
      <header className={`${styles.header} ${styles.mobileHeader}`}>
        <button className={styles.menuButton} onClick={handleSidebarToggle}>
          <img src={MenuIcon} width={24} height={24} alt="Menu" />
        </button>
      </header>

      {/* Sidebar */}
      <div
        className={`${styles.sidebar} ${
          isSidebarOpen ? styles.sidebarOpen : ''
        }`}
      >
        <button className={styles.closeButton} onClick={handleSidebarToggle}>
          ×
        </button>
        <div className={styles.logoContainer}>
          <a href="/">
            <img src={LogoIcon} width={172} height={36} />
          </a>
        </div>
        <nav className={styles.sidebarNav}>
          <a
            href="https://www.appointo.me/"
            target="_blank"
            className={styles.sidebarNavItem}
          >
            Home
          </a>
          <a
            href="https://apps.shopify.com/appointo-appointments-and-bookings"
            target="_blank"
            className={styles.sidebarNavItem}
          >
            Shopify
          </a>
          {/* <a
            href="#"
            className={`${styles.sidebarNavItem} ${
              isActive ? styles.active : ''
            }`}
            onClick={handleMobileDropdownToggle}
          >
            Industries
            <img
              className={styles.dropdownArrow}
              src={DownIcon}
              width={16}
              height={16}
              alt="Dropdown"
            />
          </a>
          <div
            className={`${styles.sidebarDropdownMenu} ${
              isActive ? styles.activeMenuItem : ''
            }`}
          >
            <a href="#" className={styles.sidebarDropdownLink}>
              Link 1
            </a>
            <a href="#" className={styles.sidebarDropdownLink}>
              Link 2
            </a>
            <a href="#" className={styles.sidebarDropdownLink}>
              Link 3
            </a>
          </div> */}
          <button className={styles.ctaButton} onClick={handleCopy}>
            Copy Link
          </button>
        </nav>
      </div>
      {/* Header desktop */}
      <header className={`${styles.header} ${styles.desktopHeader}`}>
        <div className={styles.logoContainer}>
          <a href="/">
            <img src={LogoIcon} width={172} height={36} />
          </a>
        </div>
        <nav className={styles.navLinks}>
          <a
            href="https://www.appointo.me/"
            target="_blank"
            className={styles.navItem}
          >
            Home
          </a>
          <a
            href="https://apps.shopify.com/appointo-appointments-and-bookings"
            className={styles.navItem}
            target="_blank"
          >
            Shopify
          </a>
          {/* <a href="#" className={styles.navItem} onClick={handleDropdownToggle}>
            Industries
            <img
              className={styles.dropdownArrow}
              src={DownIcon}
              width={16}
              height={16}
            />
            <div className={styles.dropdownMenu}>
              <a href="#" className={styles.dropdownLink}>
                Link 1
              </a>
              <a href="#" className={styles.dropdownLink}>
                Link 2
              </a>
              <a href="#" className={styles.dropdownLink}>
                Link 3
              </a>
            </div>
          </a>
          */}
        </nav>
        <div className={styles.actions}>
          <button className={styles.ctaButton} onClick={handleCopy}>
            Copy Link
          </button>
        </div>
      </header>
    </>
  )
}

export default Header
