import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { locations } from '../utils/constants'
import styles from '../styles/homepage.module.css'
import EditIcon from '../../assets/images/edit.svg'
import Select from './Select'
import Header from './Header'
import api from '../api/index'
import Loader from './Loader'
import EditTime from './EditTime'
import TimezoneCardList from './TimezoneCardList'
import BottomContent from './BottomContent'

const HomePage = () => {
  const [currentTime, setCurrentTime] = useState(moment())
  const [editedTime, setEditedTime] = useState(null)
  const [timezone, setTimezone] = useState('')
  const [loading, setLoading] = useState(true)
  const [timezones, setTimezones] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [localLocations, setLocalLocations] = useState(locations)

  useEffect(() => {
    const tz = moment.tz.guess()
    setTimezone(tz)

    const interval = setInterval(() => {
      setCurrentTime(moment())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    fetchTimeZones()
  }, [])

  const fetchTimeZones = async () => {
    try {
      const resp = await api.get(`worldclock/locations`)
      setTimezones(resp)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }

  const getTimeInSelectedTimezone = () => {
    const timeToUse = editedTime || currentTime
    return timezone ? timeToUse.tz(timezone) : moment()
  }

  const gmtOffset = timezone ? moment.tz(timezone).format('Z') : ''
  const formattedTimezone = timezone
    ? `(GMT${gmtOffset}) ${timezone}`
    : 'Loading...'

  const handleEdit = () => {
    const currentTimeInSelectedTimezone = getTimeInSelectedTimezone()
    setEditedTime(currentTimeInSelectedTimezone)
    setIsEdit(true)
  }

  const handleSaveEdit = (newTime) => {
    setEditedTime(newTime)
    setIsEdit(false)
  }

  return (
    <div>
      <Header />
      <div className={styles.main_time_container}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={styles.selection_wrapper}>
              <Select
                options={timezones}
                labelKey="city_name"
                selectedOption={timezone}
                setSelectedOption={setTimezone}
              />
            </div>
            <h1 className={styles.main_heading}>
              Time Zone Converter powered by Appointo
            </h1>
          </>
        )}
        <div className={styles.banner_wrapper}>
          <p className={styles.main_date}>
            {timezone && currentTime ? (
              getTimeInSelectedTimezone().format('dddd D MMMM YYYY')
            ) : (
              <Loader />
            )}
          </p>
          <p className={styles.main_time}>
            {isEdit ? (
              <EditTime
                currentTime={editedTime || currentTime}
                setCurrentTime={handleSaveEdit}
                setIsEdit={setIsEdit}
                selectedTimezone={timezone}
              />
            ) : (
              <>
                {timezone && currentTime
                  ? getTimeInSelectedTimezone().format('hh:mm A')
                  : 'Loading..'}
                <button className={styles.edit_btn} onClick={handleEdit}>
                  <img src={EditIcon} width={32} height={32} />
                </button>
              </>
            )}
          </p>
          <p className={styles.main_timezone}>{formattedTimezone}</p>
        </div>
      </div>

      <div className={styles.card_wrapper}>
        <TimezoneCardList
          setLocalLocations={setLocalLocations}
          localLocations={localLocations}
          getTimeInSelectedTimezone={getTimeInSelectedTimezone}
          timezones={timezones}
        />
        <p className={styles.bottom_text}>
          <span className={styles.bottom_text_medium}>
            Schedule an appointment?
          </span>{' '}
          Checkout{' '}
          <a
            className={styles.link}
            href="https://www.appointo.me/"
            target="_blank"
          >
            Appointo{' '}
          </a>
          for beautiful, modern booking links
        </p>
      </div>

      <BottomContent currentTime={currentTime} timezones={timezones} />
    </div>
  )
}

export default HomePage
