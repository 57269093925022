import React, { useEffect, useState } from 'react'
import styles from '../styles/homepage.module.css'
import TimezoneCard, { EmptyCard } from './TimezoneCard'

const TimezoneCardList = ({
  localLocations,
  setLocalLocations,
  getTimeInSelectedTimezone,
  timezones,
}) => {
  const handleEmptyCardClick = () => {
    setShowTimezoneDropdown((prev) => !prev)
  }
  const [showTimezoneDropdown, setShowTimezoneDropdown] = useState(false)

  const handleSelectTimezone = (selectedTimezone) => {
    setLocalLocations([...localLocations, selectedTimezone])
    setShowTimezoneDropdown(false)
  }

  return (
    <div className={styles.timezone_list_container}>
      {localLocations.map((location, index) => (
        <TimezoneCard
          currentTime={getTimeInSelectedTimezone()}
          key={index}
          location={location}
        />
      ))}
      <EmptyCard
        onClick={handleEmptyCardClick}
        showTimezoneDropdown={showTimezoneDropdown}
        timezones={timezones}
        handleSelectTimezone={handleSelectTimezone}
      />
    </div>
  )
}

export default TimezoneCardList
