import React, { useState } from 'react'
import moment from 'moment-timezone'
import { useNavigate } from 'react-router-dom'
import styles from '../styles/timezoneCard.module.css'
import CloseIcon from '../../assets/images/close.svg'
import PlusIcon from '../../assets/images/plus-circle.svg'
import { countryCodes } from '../utils/constants'
import ReactCountryFlag from 'react-country-flag'

export const EmptyCard = ({
  onClick,
  showTimezoneDropdown,
  timezones,
  handleSelectTimezone,
}) => {
  const [searchQuery, setSearchQuery] = useState('')

  const filteredOptions = timezones.filter(
    (option) =>
      option.city_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      option.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      option.value.toLowerCase().includes(searchQuery.toLowerCase())
  )
  return (
    <div className={styles.empty_card_wrapper}>
      <div className={styles.empty_card_container} onClick={onClick}>
        <div className={styles.content}>
          <img src={PlusIcon} width={48} height={48} />
          <p className={styles.content_text}>Add a new time zone</p>
        </div>
      </div>
      {showTimezoneDropdown && (
        <div className={styles.custom_dropdown}>
          <div className={styles.dropdown_content}>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.search_input}
            />
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  className={styles.dropdown_option}
                  onClick={() => handleSelectTimezone(option)}
                >
                  {option.city_name}
                </div>
              ))
            ) : (
              <div className={styles.no_options}>No matching options</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const TimezoneCard = ({ location, currentTime }) => {
  const navigate = useNavigate()

  const formattedTime = moment(currentTime).tz(location.value).format('hh:mm A')

  const formattedDate = moment(currentTime).tz(location.value).format('D MMMM')
  const countryCode = countryCodes[location.value]

  return (
    <div
      className={styles.card_wrapper}
      onClick={() => navigate(`/${location.value}`)}
    >
      {/* <button className={styles.close_btn}>
        <img
          className={styles.close_btn_image}
          src={CloseIcon}
          alt="close icon"
        />
      </button> */}
      <h2 className={styles.heading}>{location.city_name}</h2>
      <p className={styles.date}>{formattedDate}</p>
      <p className={styles.time}>{formattedTime}</p>
      <div className={styles.card_bottom}>
        {countryCode && (
          <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{ width: '24px', height: '16px' }}
          />
        )}
        <p className={styles.timezone}>{location.value}</p>
      </div>
    </div>
  )
}

export default TimezoneCard
