import React from 'react'
import styles from '../styles/cities.module.css'
import { useNavigate } from 'react-router-dom'

const CityCard = ({ type = 'city', city, currentTime }) => {
  const getTimeInSelectedTimezone = (timezone, time) => {
    const timeToUse = time
    return timezone ? timeToUse.tz(timezone) : moment()
  }
  const navigate = useNavigate()
  return (
    <div
      onClick={() => {
        if (type == 'city') {
          navigate(`/city/${city.city_name}`)
        } else if (type == 'timezone') {
          navigate(`/${city.value}`)
        }
      }}
      className={styles.city_card}
    >
      <h3 className={styles.city_name}>{city.city_name}</h3>
      <div className={styles.city_time}>
        {getTimeInSelectedTimezone(city.value, currentTime).format('hh:mm A')}
      </div>
    </div>
  )
}

export default CityCard
