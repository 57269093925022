import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import styles from '../styles/editTime.module.css'

const EditTime = ({
  currentTime,
  setCurrentTime,
  setIsEdit,
  selectedTimezone,
}) => {
  const [editedHour, setEditedHour] = useState('')
  const [editedMinute, setEditedMinute] = useState('')
  const [amPm, setAmPm] = useState('')

  useEffect(() => {
    const timeInSelectedZone = currentTime.clone().tz(selectedTimezone)
    const formattedTime = timeInSelectedZone.format('hh:mm A')
    const [hour, minute] = formattedTime.split(':')
    const [min, meridian] = minute.split(' ')

    setEditedHour(hour)
    setEditedMinute(min)
    setAmPm(meridian)
  }, [currentTime, selectedTimezone])

  const handleSave = () => {
    const newTimeString = `${editedHour}:${editedMinute} ${amPm}`
    const newTime = moment.tz(`${newTimeString}`, 'hh:mm A', selectedTimezone)
    setCurrentTime(newTime)
    setIsEdit(false)
  }

  return (
    <div className={styles.edit_wrapper}>
      <input
        type="number"
        value={editedHour}
        onChange={(e) => setEditedHour(e.target.value)}
        min="1"
        max="12"
        className={styles.input}
      />{' '}
      {' : '}
      <input
        type="number"
        value={editedMinute}
        onChange={(e) => setEditedMinute(e.target.value)}
        min="0"
        max="59"
        className={styles.input}
      />
      <select
        value={amPm}
        className={styles.period_input}
        onChange={(e) => setAmPm(e.target.value)}
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
      <button className={styles.ctaButton} onClick={handleSave}>
        Save
      </button>
    </div>
  )
}

export default EditTime
