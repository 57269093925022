import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar()

  const showToast = useCallback(
    (message, variant = 'default', options = {}) => {
      enqueueSnackbar(message, { variant, ...options })
    },
    [enqueueSnackbar]
  )

  return { showToast }
}

export default useToast
