import React, { useState } from 'react'
import styles from '../styles/select.module.css'
import LocationIcon from '../../assets/images/location.svg'

const Select = ({
  options,
  labelKey = 'city_name',
  valueKey = 'value',
  selectedOption,
  setSelectedOption,
  leftIcon = LocationIcon,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const handleSelect = (option) => {
    setSelectedOption(option[valueKey])
    setShowDropdown(false)
  }

  const filteredOptions = options.filter(
    (option) =>
      option.city_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      option.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      option.value.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <div className={styles.dropdownWrapper}>
      <div
        className={styles.dropdown}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {leftIcon && (
          <img className={styles.left_icon} src={leftIcon} alt="location" />
        )}
        <div className={styles.selectedOption}>
          {selectedOption
            ? options.find((option) => option[valueKey] === selectedOption)?.[
                labelKey
              ]
            : 'Select a timezone'}
        </div>
      </div>

      {showDropdown && (
        <div className={styles.custom_dropdown}>
          <div className={styles.dropdown_content}>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.search_input}
            />
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  className={styles.dropdown_option}
                  onClick={() => handleSelect(option)}
                >
                  {option[labelKey]}
                </div>
              ))
            ) : (
              <div className={styles.no_options}>No matching options</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Select
